import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import RecentPost from "./RecentPost"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { GoTriangleRight } from "react-icons/go"

const getRecents = graphql`
  query Recent {
    items: allFile(
      filter: {
        sourceInstanceName: { eq: "generated" }
        extension: { eq: "mdx" }
        childMdx: {
          frontmatter: { type: { eq: "POST" } }
          fields: { show: { eq: true } }
        }
      }
      sort: { order: DESC, fields: childMdx___frontmatter___published_on }
      limit: 5
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              type
              title
              description
              published_on(formatString: "MMM DD, YYYY")
              slug
            }
          }
        }
      }
    }
  }
`

const Recents = () => {
  const { items } = useStaticQuery(getRecents)

  return (
    <Wrapper>
      <h2>Recent Posts</h2>
      <div className="posts">
        {items.edges.map(({ node }, index) => {
          return <RecentPost key={index} item={node} />
        })}
      </div>
      <div className="link">
        <AniLink fade className="breadcrumb" to="/posts">
          <GoTriangleRight />
          <span> Read All Posts </span>
        </AniLink>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 1rem 0;

  .posts {
  }
`

export default Recents

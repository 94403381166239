import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const CondensedNote = ({ note }) => {
  const { title, cover, slug } = note.childMdx.frontmatter

  return (
    <SectionWrapper>
      <div>
        <AniLink fade to={`/${slug}`}>
          <Img fluid={cover.childImageSharp.fluid} alt={title} />
        </AniLink>
      </div>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.section`
  text-align: center;

  .center {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
`

export default CondensedNote

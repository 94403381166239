import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Subhash Bhushan`}</h1>
    <p>{`Hi! I’m a Software Engineer, moonlighting as an Entrepreneur. I work on technologies that make building things easier.`}</p>
    <p>{`I explore the intersection of `}<strong parentName="p">{`Entrepreneurship`}</strong>{`, `}<strong parentName="p">{`Lean Startups`}</strong>{`, and `}<strong parentName="p">{`Software Architecture Paradigms`}</strong>{`
that shine at creating `}<strong parentName="p">{`Sustainable Software`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from "react"
import Layout from "../components/Layout"
import Bookshelf from "../components/Home/Bookshelf"
import Intro from "../components/Home/Intro"
import "../css/global.css"
import styled from "styled-components"
import SEO from "../components/SEO"
import Recents from "../components/Home/Recent"

const Index = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <IndexWrapper>
        <div className="container">
          <Intro />
          <Recents />
          <Bookshelf />
        </div>
      </IndexWrapper>
    </Layout>
  )
}

const IndexWrapper = styled.div`
  @media screen and (max-width: 1230px) {
    .container {
      padding: 0 1em;
    }
  }
`

export default Index

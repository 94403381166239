import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import CondensedNote from "../Notes/CondensedNote"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { GoTriangleRight } from "react-icons/go"

const getNotes = graphql`
  query getNotes {
    notes: allFile(
      filter: {
        sourceInstanceName: { eq: "generated" }
        extension: { eq: "mdx" }
        childMdx: {
          frontmatter: { type: { eq: "BOOK_NOTES" } }
          fields: { show: { eq: true } }
        }
      }
      sort: { order: DESC, fields: childMdx___frontmatter___published_on }
      limit: 5
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              title
              slug
              cover {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Bookshelf = () => {
  const { notes } = useStaticQuery(getNotes)

  return (
    <SectionWrapper>
      <h2>Bookshelf</h2>
      <div className="shelf">
        <div>
          <p>
            Detailed notes from books I have read, complete with summaries and
            learnings.
          </p>
          <div className="link">
            <AniLink fade className="breadcrumb" to="/notes">
              <GoTriangleRight />
              <span> Browse All Notes </span>
            </AniLink>
          </div>
        </div>
        <div className="center">
          {notes.edges.map(({ node }, index) => {
            return <CondensedNote key={index} note={node} />
          })}
        </div>
      </div>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.section`
  padding: 1rem 0;

  .breadcrumb {
    list-style-type: none;
    transition: var(--mainTransition);
    color: var(--mainBlack);

    svg {
      font-size: 0.8rem;
    }
  }

  .center {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  .link {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 300px) {
    .shelf {
      display: flex;
      flex-direction: column;
    }
    .center {
      grid-template-columns: repeat(3, minmax(50px, 1fr));
    }
  }
  @media screen and (min-width: 600px) {
    .shelf {
      display: flex;
      flex-direction: column;
    }
    .center {
      grid-template-columns: repeat(5, minmax(50px, 1fr));
    }
  }
  @media screen and (min-width: 1175px) {
    .shelf {
      display: grid;
      grid-template-columns: 30% auto;
    }

    .center {
      grid-template-columns: repeat(5, minmax(100px, 1fr));
    }
  }
`

export default Bookshelf

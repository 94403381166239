import React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const RecentPost = ({ item }) => {
  const { title, description, slug, published_on } = item.childMdx.frontmatter

  return (
    <SectionWrapper>
      <div>
        <AniLink fade to={`/${slug}`}>
          <h3>{title}</h3>
        </AniLink>
        <div className="published_on">{published_on}</div>
        <div
          className="desc"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.section`
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  background-color: #f7f6f6;
  border-radius: 10px;

  h3 {
    margin-bottom: 0rem;
  }

  .published_on {
    margin-bottom: 1rem;
    color: gray;
    font-weight: 600;
  }
`

export default RecentPost

import React from "react"
import styled from "styled-components"
import IntroContent from "../../../content/intro.mdx"

const Intro = () => {
  return (
    <SectionWrapper>
      <IntroContent />
    </SectionWrapper>
  )
}

const SectionWrapper = styled.section`
  @media screen and (min-width: 998px) {
    width: 50%;
  }
`

export default Intro
